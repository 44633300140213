define("discourse/plugins/discourse-staff-alias/discourse/templates/connectors/revision-user-details-after/aliased-staff-user-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.is_staff_aliased}}
    <div class="aliased-staff-user-details">
      {{#if this.model.aliased_username}}
        <LinkTo
          @route="user"
          @model={{this.model.aliased_username}}
          title={{i18n
            "discourse_staff_alias.post_revision_icon_title"
            username=this.model.aliased_username
          }}
        >
          <span>({{d-icon "user-secret"}} {{this.model.aliased_username}})</span>
        </LinkTo>
      {{else}}
        <span>
          ({{d-icon "user-secret"}}
          {{i18n "discourse_staff_alias.aliased_user_deleted"}})
        </span>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "06UDDXAs",
    "block": "[[[41,[30,0,[\"model\",\"is_staff_aliased\"]],[[[1,\"  \"],[10,0],[14,0,\"aliased-staff-user-details\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"aliased_username\"]],[[[1,\"      \"],[8,[39,1],[[16,\"title\",[28,[37,2],[\"discourse_staff_alias.post_revision_icon_title\"],[[\"username\"],[[30,0,[\"model\",\"aliased_username\"]]]]]]],[[\"@route\",\"@model\"],[\"user\",[30,0,[\"model\",\"aliased_username\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[12],[1,\"(\"],[1,[28,[35,3],[\"user-secret\"],null]],[1,\" \"],[1,[30,0,[\"model\",\"aliased_username\"]]],[1,\")\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[12],[1,\"\\n        (\"],[1,[28,[35,3],[\"user-secret\"],null]],[1,\"\\n        \"],[1,[28,[35,2],[\"discourse_staff_alias.aliased_user_deleted\"],null]],[1,\")\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-staff-alias/discourse/templates/connectors/revision-user-details-after/aliased-staff-user-details.hbs",
    "isStrictMode": false
  });
});